import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Products"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            We’re just enthusiastic about what we do.
            <br />
            &mdash; Steve Jobs
          </h2>
          <h3>&nbsp;</h3>
          <p>
            Some of the stuff we make is available on Etsy. Feel free to check
            out our{" "}
            <a
              href="//parkerandleigh.etsy.com"
              title="Etsy Shop"
              target="_blank"
              rel="noopener noreferrer"
            >
              store!
            </a>
          </p>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.ourProductsHero.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>
              Calavera Skull Family &mdash;{" "}
              <a
                title="Etsy Listing for Calavera Skull Family"
                href="https://www.etsy.com/listing/481394858/calavera-skull-family-car-decal"
                target="_blank"
                rel="noopener noreferrer"
              >
                View on Etsy
              </a>
            </figcaption>
          </figure>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    ourProductsHero: file(relativePath: { eq: "our-products-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
